// import React from 'react';

export type FormFieldSize = 'normal' | 'small' | 'extraSmall';

export const DEFAULT_FIELD_SIZE: FormFieldSize = 'normal';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface BaseFieldProps<Element = HTMLInputElement> {
  name?: string;
  disabled?: boolean;
  error?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (value: any) => void; // React.ChangeEventHandler<Element> |
}
