import { DialogListRequest, DialogListResponse } from '@common/model/dialog/dialogListRequest';
import { DialogViewModel } from '@common/model/dialog/dialogViewModel';
import { DialogMessagesRequest, DialogMessagesResponse } from '@common/model/dialog/dialogMessagesRequest';
import { DialogMessageViewModel } from '@common/model/dialog/message/dialogMessageViewModel';
import { DialogMessageCreateRequest } from '@common/model/dialog/message/dialogMessageCreateRequest';
import { DialogCreateRequest } from '@common/model/dialog/dialogCreateRequest';
import { coreApi } from '@cp/shared/api/core';
import { UnreadMessagesCountResponse } from '@common/model/dialog/unreadMessagesCount.response';
import { DialogMemberViewModel } from '@common/model/dialog/dialogMemberViewModel';
import { UnreadMessagesCountRequest } from '@common/model/dialog/unreadMessagesCount.request';
import { BaseQueryArg } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { EntityId } from '@reduxjs/toolkit';
import { CoreDialogApiTags, coreDialogApiTagTypes } from './tags';

const DIALOGS_URL = '/dialogs';

export const coreDialogApi = coreApi.enhanceEndpoints({ addTagTypes: coreDialogApiTagTypes }).injectEndpoints({
  endpoints: (builder) => ({
    getDialogs: builder.query<DialogListResponse, DialogListRequest>({
      query: ({ page = 1, pagination }) => ({
        url: DIALOGS_URL,
        params: {
          page,
          pagination,
        },
      }),
      providesTags: [CoreDialogApiTags.DialogList],
      keepUnusedDataFor: 0,
    }),

    getDialog: builder.query<DialogViewModel, { id: number }>({
      query: ({ id }) => ({
        url: `${DIALOGS_URL}/${id}`,
      }),
      providesTags: (result, error, arg) => [{ type: CoreDialogApiTags.Dialog, id: arg.id }],
    }),

    getMessages: builder.query<DialogMessagesResponse, DialogMessagesRequest & { id: number }>({
      query: ({ id, ...params }) => ({
        url: `${DIALOGS_URL}/${id}/messages`,
        params,
      }),
      providesTags: (result, error, arg) => [{ type: CoreDialogApiTags.Messages, id: arg.id }],
    }),

    getUnreadMessagesCount: builder.query<UnreadMessagesCountResponse, UnreadMessagesCountRequest>({
      query: ({ filter }) => ({
        url: `${DIALOGS_URL}/unread-messages-count`,
        params: { filter },
      }),
      merge(current, response) {
        if (new Date(current.updatedAt) > new Date(response.updatedAt)) {
          return current;
        }
        return response;
      },
      providesTags: () => [{ type: CoreDialogApiTags.UnreadMessagesCount }],
    }),

    sendMessage: builder.mutation<DialogMessageViewModel, DialogMessageCreateRequest<any>>({
      query: (body) => ({
        url: `${DIALOGS_URL}/${body.dialogId}/messages`,
        method: 'POST',
        body,
      }),
      // invalidatesTags: (result, error, arg) => [{ type: CoreDialogApiTags.Messages, id: arg.dialogId }],
    }),

    canSendMessage: builder.query<boolean, { dialogId: EntityId }>({
      query: (body) => ({
        url: `${DIALOGS_URL}/${body.dialogId}/messages`,
        method: 'OPTIONS',
        body,
      }),
      transformResponse: (res) => {
        // eslint-disable-next-line eqeqeq
        return res == '0';
      },
    }),

    createDialog: builder.mutation<DialogViewModel, DialogCreateRequest>({
      query: (body) => ({
        url: `${DIALOGS_URL}/ensure`,
        method: 'POST',
        body,
      }),
    }),

    joinDialog: builder.mutation<DialogMemberViewModel, { dialogId: number }>({
      query: ({ dialogId }) => ({
        url: `${DIALOGS_URL}/${dialogId}/join`,
        method: 'POST',
      }),
    }),

    leaveDialog: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `${DIALOGS_URL}/${id}/leave`,
        method: 'POST',
      }),
    }),

    markRead: builder.mutation<void, { dialogId: number; messageId: number }>({
      query: ({ dialogId, messageId }) => ({
        url: `${DIALOGS_URL}/${dialogId}/messages/${messageId}/read`,
        method: 'POST',
      }),
      invalidatesTags: (_, error) => {
        if (error) {
          return [];
        }
        return [{ type: CoreDialogApiTags.UnreadMessagesCount }];
      },
    }),
  }),
});
