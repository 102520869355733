export enum ClientWebPage {
  Home = 1,
  Catalog = 2,
  CatalogItem = 3,
  AboutUs = 4,
  PaymentAndRefund = 5,
  FAQ = 6,
  Feedbacks = 8,
  Contacts = 9,
  LegalInformation = 10,
  PrivacyPolicy = 11,
  NotFound = 12,
  WriteToDirector = 13,
  UserAgreement = 14,
  Support = 15,
  SignupProvider = 16,
  Profile = 17,
  ProfileVacancies = 19,
  ProfileFavorites = 20,
  ProfilePhoto = 21,
  LegacyPaymentFinish = 23,
  ProfileMessages = 24,
  Confirmation = 26,
  OAuth = 27,
  Team = 28,
  ProfileSchedule = 32,
  ProfileRegularPartners = 33,
  ProfileBilling = 34,
  Intro = 35,
  Insurance = 36,
  Cities = 37,
  Sitemap = 38,
}
