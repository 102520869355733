import React from 'react';
import { Typography } from '../../../../../../typography';
import { useTheme } from '../../../../../../../hooks/useTheme';
import { mergeSx } from '../../../../../../../utils/sx/merge';
import { DEFAULT_FIELD_SIZE } from '../../model';
import { FieldLabelProps } from './model';

export const FieldLabel: React.FC<React.PropsWithChildren<FieldLabelProps>> = ({ children, sx, size = DEFAULT_FIELD_SIZE }) => {
  const theme = useTheme();

  return (
    <Typography
      component="label"
      sx={mergeSx({ mb: 1.5, color: theme.palette[size === 'small' ? 'gray' : 'brand gray'] }, sx)}
      variant={size === 'small' ? 'caption bold' : 'body bold'}
    >
      {children}
    </Typography>
  );
};
