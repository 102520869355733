import { isValidDate } from '@cp/common/date';
import { FormattingStrategy } from './formattingStrategy';

export const getClientLastSeen = (strategy: FormattingStrategy, lastActivityAt: string = '', now = new Date()): string => {
  const lastActivityDate = new Date(lastActivityAt);

  if (!isValidDate(lastActivityDate)) {
    return '';
  }

  return strategy(lastActivityDate, now);
};
