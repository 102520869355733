import { coreApi } from '@cp/shared/api/core';
import { ClientWebRouteMetaViewModel } from '@common/model/meta/clientWebRouteMetaViewModel';
import { RouterClientWebRoutesResponse } from './clientWebRoutes/response';
import { ClientWebRouteMetaRequest } from './clientWebRouteMeta/request';
import { ClientWebRouteMetaResponse } from './clientWebRouteMeta/response';
import { CoreApiMetaTags, coreApiMetaTagTypes } from './tags';

export const coreRoutesApi = coreApi.enhanceEndpoints({ addTagTypes: coreApiMetaTagTypes }).injectEndpoints({
  endpoints: (builder) => ({
    getClientWebRouteMeta: builder.query<ClientWebRouteMetaResponse, ClientWebRouteMetaRequest>({
      query: ({ routeId }) => ({
        url: `meta/client-web-routes/${routeId}/meta`,
      }),
      providesTags: (result, error, { routeId }) => [{ type: CoreApiMetaTags.ClientWebRouteMeta, id: `route-${routeId}` }],
    }),

    getClientWebRoutesMeta: builder.query<ClientWebRouteMetaViewModel[], void>({
      query: () => ({
        url: 'meta/client-web-routes-meta',
      }),
      providesTags: () => [CoreApiMetaTags.ClientWebRoutesMeta],
    }),

    getClientWebRoutes: builder.query<RouterClientWebRoutesResponse, void>({
      query: () => ({
        url: 'meta/client-web-routes',
      }),
      providesTags: [{ type: CoreApiMetaTags.ClientWebRoutes }],
    }),
  }),

  overrideExisting: false,
});
