/* eslint-disable @typescript-eslint/no-magic-numbers */
import React, { KeyboardEventHandler } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FormField } from '@cp/ds/src/components/form';
import { Typography } from '@cp/ds/src/components/typography';
import { TextAreaAutoSizeProps } from '@cp/ds/src/components/form/components/field/textAreaAutoSize/model';
import { FormFieldOwnProps } from '@cp/ds/src/components/form/components/field/model';

export interface MessageInputProps {
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any>;
  sendBySms?: boolean;
  messageFieldProps?: Omit<FormFieldOwnProps<'message'>, 'name'> & Omit<TextAreaAutoSizeProps, 'type'>;
  onKeyDown?: KeyboardEventHandler<HTMLTextAreaElement>;
}

const MESSAGE_MAX_LENGTH_WHEN_SMS_COPY_IS_REQUIRED = 134;

const calcSmsCount = (length: number) => {
  if (length < 71) {
    return 1;
  } else if (length < 135) {
    return 2;
  } else if (length < 202) {
    return 3;
  } else if (length < 269) {
    return 4;
  } else {
    return 5;
  }
};

export const MessageInput = React.forwardRef<HTMLTextAreaElement, MessageInputProps>(
  ({ form, onKeyDown, sendBySms, disabled, messageFieldProps }, ref) => {
    const message = form.watch('message') ?? '';

    React.useEffect(() => {
      if (sendBySms && message.length > MESSAGE_MAX_LENGTH_WHEN_SMS_COPY_IS_REQUIRED) {
        form.setError('message', {
          type: 'maxLength',
          message: `Максимальная рекомендуемая длина смс сообщения ${MESSAGE_MAX_LENGTH_WHEN_SMS_COPY_IS_REQUIRED} символов`,
        });
      } else {
        form.clearErrors('message');
      }
    }, [form, message, sendBySms]);

    return (
      <div
        style={{
          flexGrow: 1,
        }}
      >
        <FormField
          disabled={disabled}
          fieldContainerProps={{
            sx: {
              flexGrow: 1,
              flexDirection: 'row',
            },
          }}
          maxRows={5}
          minRows={1}
          name="message"
          onKeyDown={onKeyDown}
          placeholder="Написать..."
          ref={ref}
          size="small"
          sx={{
            flexGrow: 1,
          }}
          type="textAreaAutoSize"
          {...messageFieldProps}
        />
        {sendBySms && (
          <Typography sx={{ mt: 0.5, color: 'gray' }} variant="caption">
            до 1000 символов, введено: {message.length}, sms - {calcSmsCount(message.length)}
          </Typography>
        )}
      </div>
    );
  },
);
