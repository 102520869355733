export enum CoreClientApiTags {
  ClientProfile = 'ClientProfile',
  ClientOwnCatalogItemResponses = 'ClientOwnCatalogItemResponses',
  ClientOwnCatalogItemViews = 'ClientOwnCatalogItemViews',
}

export enum ApiTags {
  BillingTariffList = 'BillingTariffList',

  ClientPaymentCardList = 'ClientPaymentCardList',
  ClientBillingSubscription = 'ClientBillingSubscription',
}

// TODO: separate tags to api enums
export const coreServiceTagTypes = [
  'FAQList',
  'CareerList',
  'CatalogItem',
  'ResumeFeedbacks',
  ...Object.values(CoreClientApiTags),
  ...Object.values(ApiTags),
] as const;
