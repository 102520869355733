import React from 'react';
import { SvgIcon } from '../components/svgIcon';
import { SvgIconProps } from '../components/svgIcon/model';

export const CheckmarkIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="checkmark" ref={ref as any} {...props}>
      <path
        d="M18.6676 7.68501L18.2634 7.30988C17.8198 6.89671 17.0928 6.89671 16.6477 7.30988L9.78602 13.6871L7.35119 11.4254C6.90751 11.0127 6.18012 11.0127 5.73645 11.4258L5.33276 11.8005C4.88908 12.2133 4.88908 12.8888 5.33276 13.3015L8.97629 16.6908C9.42044 17.1031 10.1474 17.1031 10.5915 16.6908L18.6676 9.18597C19.1108 8.77324 19.1108 8.09774 18.6676 7.68501Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});

export const CheckmarkFilledIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <SvgIcon cacheName="checkmarkFilled" ref={ref as any} {...props}>
      <circle cx="12" cy="12" fill="curentColor" r="12"></circle>
      <path
        d="M18.6676 7.68501L18.2634 7.30988C17.8198 6.89671 17.0928 6.89671 16.6477 7.30988L9.78602 13.6871L7.35119 11.4254C6.90751 11.0127 6.18012 11.0127 5.73645 11.4258L5.33276 11.8005C4.88908 12.2133 4.88908 12.8888 5.33276 13.3015L8.97629 16.6908C9.42044 17.1031 10.1474 17.1031 10.5915 16.6908L18.6676 9.18597C19.1108 8.77324 19.1108 8.09774 18.6676 7.68501Z"
        fill="#fff"
      />
    </SvgIcon>
  );
});
