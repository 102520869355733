export const GOOGLE_MERCHANT_ID = 'BCR2DN6TTOQZTGT4';
export const APPLE_MERCHANT_ID = 'merchant.ru.youhelp';

export enum PaymentSystem {
  Unknown = 'Unknown',
  Visa = 'Visa',
  Maestro = 'Maestro',
  MasterCard = 'MasterCard',
  Mir = 'MIR',
}
