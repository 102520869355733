import React from 'react';
import { Typography } from '@cp/ds/src/components/typography';
import { InfoSolidIcon } from '@cp/ds/src/icons';
import { useTheme } from '@cp/ds/src/hooks';
import { NotFilledProps } from './model';

export const NotFilled: React.FC<NotFilledProps> = ({ children }) => {
  const theme = useTheme();

  return (
    <Typography
      component="div"
      sx={{
        py: 0.25,
        px: 1,
        borderRadius: '6px',
        backgroundColor: theme.palette['brand pink hover'],
        color: theme.palette.white,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 0.5,
      }}
      variant="small text bold"
    >
      <InfoSolidIcon fontSize="1rem" />
      <span>{children}</span>
    </Typography>
  );
};
