import styled from '@mui/material/styles/styled';
import { linkColorDict } from '../../const';
import { LINK_COMPONENT_DEFAULT, LinkOwnerState } from '../../model';

export const LinkRoot = styled(LINK_COMPONENT_DEFAULT, {
  name: 'Link',
  slot: 'Root',
})<{ ownerState: LinkOwnerState }>(({ theme, ownerState }) => {
  const { color, borderColor, hoverColor, hoverBorderColor } = linkColorDict[ownerState.color][ownerState.size];

  const fontVariant = {
    'small': 'caption bold',
    'normal': 'body bold',
  };

  return {
    ...theme.typography[fontVariant[ownerState.size]],
    position: 'relative',
    color: theme.palette[color],
    textDecoration: 'none',
    cursor: 'pointer',
    WebkitTapHighlightColor: 'transparent',
    ...(ownerState.underline && {
      textDecoration: 'underline',
      textDecorationLine: 'underline',
      textDecorationStyle: ownerState.size === 'normal' ? 'dotted' : 'dotted',
      textDecorationColor: theme.palette[borderColor],
      textUnderlineOffset: ownerState.size === 'normal' ? 3 : 2,
      // '&:after': {
      //   content: '""',
      //   // display: 'block',
      //   position: 'absolute',
      //   width: '100%',
      //   bottom: ownerState.size === 'normal' ? 0 : 2,
      //   left: 0,
      //   right: 0,
      //   borderBottom: `1px ${ownerState.size === 'normal' ? 'dashed' : 'dotted'} ${theme.palette[borderColor]}`,
      // },
    }),
    '@media (pointer: fine)': {
      '&:hover': !ownerState.disableHover && {
        color: theme.palette[hoverColor],
        ...(ownerState.underline && {
          // '&:after': {
          //   borderBottomColor: theme.palette[hoverBorderColor],
          // },
          textDecorationColor: theme.palette[hoverBorderColor],
        }),
      },
    },
  };
});
