import React from 'react';
import { ClientNameAndSurname, ClientPersonalInformation } from '@cp/entities/client/some/profile';
import { EditableSection } from '@cp/ds/src/components/editableSection';
import { useModal } from 'src/app/shared/hooks/useModal';
import { CLIENT_PERSONAL_INFORMATION_EDIT_MODAL_NAME } from 'src/app/shared/components/modals/clientPersonalInformationEdit/const';
import { ClientPersonalInformationEditableSectionProps } from './model';

export * from './model';

export const ClientPersonalInformationEditableSection: React.FC<ClientPersonalInformationEditableSectionProps> = ({
  clientProfile,
  isLoading,
  sx,
}) => {
  const { openModal } = useModal();

  return (
    <EditableSection
      isLoading={isLoading}
      onEdit={() => {
        if (clientProfile) {
          openModal(CLIENT_PERSONAL_INFORMATION_EDIT_MODAL_NAME, { clientProfile });
        }
      }}
      sx={sx}
      title={clientProfile ? <ClientNameAndSurname asPlainText clientProfile={clientProfile} /> : null}
    >
      <ClientPersonalInformation clientProfile={clientProfile} disableTopRow showEmail />
    </EditableSection>
  );
};
