export enum ApiRoute {
  AdminBillingPromocodeCreate = 'AdminBillingPromocodeCreate',

  AdminClientPatronageUpdate = 'AdminClientPatronageUpdate',
  AdminClientPatronageGet = 'AdminClientPatronageGet',

  AdminClientInterviewSchedule = 'AdminClientInterviewSchedule',

  AdminCatalogItemsGet = 'AdminCatalogItemsGet',
  AdminCatalogItemGetOne = 'AdminCatalogItemGetOne',
  AdminCatalogItemDelete = 'AdminCatalogItemDelete',
  AdminCatalogItemAnnounce = 'AdminCatalogItemAnnounce',
  AdminCatalogFeedbacksCreate = 'AdminCatalogFeedbacksCreate',
  AdminCatalogFeedbacksDelete = 'AdminCatalogFeedbacksDelete',

  AdminResumesUpdate = 'AdminResumeUpdate',
  AdminResumesScheduleIntervalsCreate = 'AdminResumesScheduleIntervalsCreate',
  AdminResumesScheduleIntervalsDelete = 'AdminResumesScheduleIntervalsDelete',

  BillingTariffs = 'BillingTariffs',
  BillingTariffsGetOne = 'BillingTariffsGetOne',
  BillingPaymentsCallbacksCloudpayments3DS = 'BillingPaymentsCallbacksCloudpayments3DS',
  CatalogOptionsGet = 'CatalogOptionsGet',

  ClientLogin = 'ClientLogin',
  ClientAuth = 'ClientAuth',
  ClientRegistration = 'ClientRegistration',
  ClientDelete = 'ClientDelete',
  ClientProfileGet = 'ClientProfileGet',
  ClientProfileUpdate = 'ClientProfilePut',
  ClientProfileOTPSCompleteChallenge = 'ClientProfileOTPSCompleteChallenge',
  ClientPrefillParametersGet = 'ClientPrefillParametersGet',

  ClientResumesGetOne = 'ClientResumesGetOne',
  ClientResumesUpdate = 'ClientResumesUpdate',
  ClientResumesScheduleIntervalsCreate = 'ClientResumesScheduleIntervalCreate',
  ClientResumesScheduleIntervalsDelete = 'ClientResumesScheduleIntervalDelete',
  ClientResumesGet = 'ClientResumesGet',

  ClientVacanciesCreate = 'ClientVacanciesCreate',
  ClientVacanciesUpdate = 'ClientVacanciesUpdate',
  ClientVacanciesDelete = 'ClientVacanciesDelete',
  ClientVacanciesGetOne = 'ClientVacanciesGetOne',
  ClientVacanciesGet = 'ClientVacanciesGet',

  ClientCatalogItemResponsesGet = 'ClientCatalogItemResponsesGet',

  ClientCatalogItemViewsGet = 'ClientCatalogItemViewsGet',

  ClientFavoriteClientsCreate = 'ClientFavoriteClientsCreate',
  ClientFavoriteClientsDelete = 'ClientFavoriteClientsDelete',
  ClientFavoriteClientsGet = 'ClientFavoriteClientsGet',

  ClientNotificationsWebpushSubscribe = 'ClientNotificationsWebpushSubscribe',
  ClientNotificationsWebpushUnsubscribe = 'ClientNotificationsWebpushUnsubscribe',
  ClientNotificationsPushSubscribe = 'ClientNotificationsPushSubscribe',

  ClientRegularPartnerClientsCreate = 'ClientRegularPartnerClientsCreate',
  ClientRegularPartnerClientsDelete = 'ClientRegularPartnerClientsDelete',
  ClientRegularPartnerClientsGet = 'ClientRegularPartnerClientsGet',

  ClientTrainingStart = 'ClientTrainingStart',
  ClientTrainingFinish = 'ClientTrainingFinish',

  ClientsContactCanView = 'ClientContactCanView',
  ClientsContactCanOpen = 'ClientContactCanOpen',
  ClientsContactGet = 'ClientContactGet',
  ClientsResumeGet = 'ClientResumeGet',

  ClientBillingPaymentCardsCreate = 'ClientBillingPaymentCardCreate',
  ClientBillingPaymentCardsGet = 'ClientBillingPaymentCardsGet',
  ClientBillingPaymentCardsDelete = 'ClientBillingPaymentCardDelete',
  ClientBillingSubscriptionStart = 'ClientBillingSubscriptionStart',
  ClientBillingSubscriptionGet = 'ClientBillingSubscriptionGet',
  ClientBillingSubscriptionStop = 'ClientBillingSubscriptionStop',
  ClientBillingPromocodesActivate = 'ClientBillingPromocodesActivate',
  ClientBillingPromocodesUsagesGet = 'ClientBillingPromocodesUsagesGet',

  CatalogItemsGet = 'CatalogItemsGet',
  CatalogItemAuthorConnect = 'CatalogItemAuthorConnect',
  CatalogItemResponse = 'CatalogItemResponse',
  CatalogItemGetResponses = 'CatalogItemGetResponses',
  CatalogItemGetProposals = 'CatalogItemGetProposals',

  DealCalcPrice = 'DealCalcPrice',
  DealCreateWithClient = 'DealCreateWithClient',
  DealCreateFromVacancy = 'DealCreateFromVacancy',
  DealAccept = 'DealAccept',
  DealReject = 'DealReject',
  DealUpdatePrice = 'DealUpdatePrice',
  DealGetOne = 'DealGetOne',
  DealFeedbacksCreate = 'DealFeedbacksCreate',

  StatsClientInteractionsGet = 'StatsClientInteractionsGet',
  StatsClientInteractionDetails = 'StatsClientInteractionDetails',
  StatsClientInteractionModeratorView = 'StatsClientInteractionModeratorView',
  StatsClientSubscriptions = 'StatsClientSubscriptions',
  StatsClient = 'StatsClient',
  StatsVacancies = 'StatsVacancies',
}
